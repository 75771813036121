import React from "react"
import { Global, css } from "@emotion/react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Navbar from "./bb/navbar"
import Footer from "./bb/footer"
import {Link} from "gatsby"

const globalCSS = css`
  :root{
    --primary: #005089; /* blue */
    --primaryalt: 0, 80, 137; /* the same blue in RGB */
    --secondary: #33A24C; /* green */
    --secondaryalt: 51, 162, 76; /* RGB green */
    
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr min(90ch, calc(100% - 48px)) 1fr;
    grid-column-gap: 24px;
  }
  .wrapper > * {
    grid-column: 2;
  }
  .full-bleed {
    width: 100%;
    grid-column: 1 / 4;
  }
  p a{
    color: var(--primary);
    font-weight: bold;
    transition: all 0.2s;
  }
  p a:hover{
    color: #08094B;
    border-bottom: 3px solid var(--secondary);
  }
`

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
  query {
    logo: file(relativePath: {eq: "images/crookedcreekcabins_logo.png"}) {
      childImageSharp {
        fixed(width: 200){
          ...GatsbyImageSharpFixed
        }
      }
    }
    bg: file(relativePath: {regex: "/hiking2/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (
    <div className="wrapper">
      <Global styles={globalCSS} />
      <Navbar>
        <div className="logo-image">
          <Img fixed={data.logo.childImageSharp.fixed} />
        </div>
        <div className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/#cabins">Our Cabins</Link>
          <Link to="/area-information/">About the Area</Link>
          {/* <Link to="/#contact">Contact Us</Link> */}
        </div>
      </Navbar>
      {children}
      <Footer>
        <div className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/#cabins">Our Cabins</Link>
          <Link to="/area-information/">About the Area</Link>
          {/* <Link to="/#contact">Contact Us</Link> */}
        </div>
        <div itemscope itemtype="https://schema.org/LocalBusiness">
          <p>
            <span itemprop="name">Crooked Creek Cabins</span><br />
            <span itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
              <span itemprop="streetAddress">15859 Dry Hollow Rd</span>, &nbsp;
              <span itemprop="addressLocality">Boscobel</span>, &nbsp;
              <span itemprop="addressRegion">WI</span>
            </span>
          </p>
        </div>
      </Footer>
    </div>
  )
}
