import React from "react"
import { css } from "@emotion/react" 
import { useStaticQuery, graphql } from "gatsby"

const css_full = css`
  padding: 32px 0;
  margin: 0 0 24px 0;
  background: var(--primary);
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
`;

const css_base = css`
  .gatsby-image-wrapper{
      overflow: visible !important;
      display: block !important;
      margin: 0 auto;
      /* background: white; */
  }  
  .nav-links{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    gap: 24px 24px;
    margin-top: 25px;
    }
  img{
    border-radius: 50%;
    background-color: white;
    box-shadow: 2px 6px 6px rgba(0,0,0,0.3);
  }
  a{
      display: inline-block;
      text-transform: uppercase;
      text-align:center;
      font-weight: bold;
      color: white;
      border-bottom: 3px solid rgba(var(--primaryalt),0);
      transition: all 0.3s;
    }
    a:hover{
      border-bottom: 3px solid var(--secondary);
    }

  @media screen and (min-width: 769px){
    .nav-links{
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }  
`

export default function Navbar(props) {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: {regex: "/hiking2/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          src
        }
      }
    }
  }
  `)
  return (
    <div className="full-bleed" css={css_full} style={{
      backgroundImage: `url('${data.file.childImageSharp.fluid.src}')`,
      boxShadow: 'inset 0 0 0 2000px rgba(var(--primaryalt), 0.75)',
      backgroundSize: 'cover',
      backgroundPosition: 'center 20%',
    }}>
      <div className="wrapper">
        <div css={css_base}>{props.children}</div>
      </div>
    </div>
  )
}
